import ApiService from '@/common/api.service';
import Toast from '@/common/toast';
import moment from 'moment/moment';
import Vue from 'vue';

const resource = 'books';

const BookService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getDto(slug) {
        return ApiService.get(`${resource}/dto`, slug);
    },
    async getBlob(documentId) {
        return ApiService.get('blobs', documentId);
    },
    async deleteBlob(documentId) {
        return ApiService.delete('blobs', documentId);
    },
    async setBookTechAcceptor(bookId, techAcceptorId, tenantId, params, pdfassetId) {
        const path = `${resource}/${bookId}/techacceptor/${techAcceptorId}/${tenantId}/${pdfassetId}`;
        return ApiService.put(path, { params });
    },
    async moveBook2folder(bookId, folderId) {
        const path = `${resource}/${bookId}/move2folder/${folderId}`;
        return ApiService.put(path, {});
    },
    async downloadDocument(documentId) {
        const path = `${documentId}/document/download`;
        return ApiService.getPdf('blobs', path);
    },
    async mappingBarcode(slug) {
        return ApiService.get('qr/mapping', slug);
    },
    async generateBarcode(slug, payload) {
        return ApiService.post(`qr/generate/${slug}`, payload);
    },
    async getBarcode(slug) {
        return ApiService.get('qr', slug);
    },
    async getStatuses() {
        return ApiService.get(resource + '/status/all');
    },
    async getInspections(bookId) {
        const path = `${bookId}/inspection`;
        return ApiService.get(resource, path);
    },
    async getSelfInspections(bookId) {
        const path = `${bookId}/selfinspection`;
        return ApiService.get(resource, path);
    },
    async getStatistics() {
        const path = `statistics`;
        return ApiService.get(resource, path);
    },
    async getBookFromQrCode(qrCodeId) {
        const path = `${qrCodeId}/qrcode`;
        return ApiService.get(resource, path);
    },
    async getExportPdf(bookId) {
        const path = `${bookId}/exportPdf`;
        return ApiService.getPdf(resource, path);
    },
    async getExportPdfC(bookId) {
        const path = `${bookId}/exportPdfShrink`;
        return ApiService.getPdf(resource, path);
    },
    async getEtikettenPdf(params) {
        return Vue.axios
            .post(`${resource}` + '/getEtikettPdf', params, {
                responseType: 'arraybuffer',
            })
            .catch((error) => {
                throw new Error(`[pruefbuch] ApiService ${error}`);
            });
    },
    async getGutachtenPdf(bookId, gutachtenId) {
        const path = `${bookId}/gutachtenPdf/${gutachtenId}`;
        return ApiService.getPdf(resource, path);
    },
    async getMyInspections(bookId, userId) {
        const path = `${bookId}/inspectionUser/${userId}`;
        return ApiService.get(resource, path);
    },
    async getAllMyInspections(searchBy, searchTerm) {
        let path = `allMyOpenInspection`;

        if (searchTerm != null && searchTerm !== '') {
            if (searchBy === 'sn') {
                path = `allInspectionBySn/${searchTerm}`;
            } else if (searchBy === 'zip') {
                path = `allInspectionByZip/${searchTerm}`;
            } else if (searchBy === 'orderNo') {
                path = `allInspectionByOrderNo/${searchTerm}`;
            }
        }
        return ApiService.get(resource, path);
    },
    async getAllInspectionsInProgress(searchBy, searchTerm, tenantId) {
        let path = `allOpenInspection/${tenantId}/`;

        if (searchTerm != null && searchTerm !== '') {
            if (searchBy === 'sn') {
                path += `BySn/${searchTerm}`;
            } else if (searchBy === 'zip') {
                path += `ByZip/${searchTerm}`;
            } else if (searchBy === 'orderNo') {
                path += `ByOrderNo/${searchTerm}`;
            }
        }
        return ApiService.get(resource, path);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async duplicateTemplate(templateId, params) {
        let promise = null;
        try {
            const path = `${resource}/fromTemplate/${templateId}`;
            promise = await ApiService.post(path, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        delete params.updatedAt;
        delete params.createdAt;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateSpezial(slug, params) {
        let promise = null;
        try {
            const path = `${resource}/updateSpezial/${slug}`;
            return ApiService.put(path, params);
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async addDocument(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + `/${slug}/document`, params);
            Toast.showSaveOkToastText('Dokument hinzugefügt!');
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delDocument(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource + `/${slug}/document`, params);
            Toast.showSaveOkToastText('Dokument gelöscht!');
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async addInspection(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + `/${slug}/inspection`, params);
            Toast.showSaveOkToastText('Anmeldung erfolgreich!');
        } catch (e) {
            // debugger;
            if (e != null && e.response != null && e.response.data != null) {
                if (e.response.data.err == 'TOO EARLY') {
                    Toast.showSaveErrorToastText('Sie sind etwas zu früh dran - probieren sie es bitte später!');
                    return;
                }
            }
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            // Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async fileUpload(file, additionalFormData) {
        let promise = null;

        let fileResource = 'blobs';

        try {
            promise = await ApiService.fileUpload(fileResource, file, additionalFormData);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async fileUploadPdf(file, additionalFormData) {
        let promise = null;
        let fileResource = 'pdfassets/uploadfile';
        try {
            promise = await ApiService.fileUpload(fileResource, file, additionalFormData);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async calculatedate(params) {
        return ApiService.post(resource + '/calculatedate', params);
    },
    getLastInspection(inspections) {
        let lastInspection = null;
        for (const inspection of inspections) {
            if (!inspection.isAbnahmeGutachten) {
                if (lastInspection === null || (moment(inspection.when).isAfter(moment(lastInspection)) && inspection.finalized)) {
                    lastInspection = moment(inspection.when);
                }
            }
        }
        return lastInspection;
    },
};

export default BookService;
