import store from '@/store';
import JwtService from '@/common/jwt.service';
import ApiService from '@/common/api.service';

export default {
    storeToken(response) {
        const auth = store.state.auth;

        JwtService.saveToken(response.data.token);
        JwtService.saveRefreshToken(response.data.refreshToken);
        ApiService.setJwtHeader();

        auth.isLoggedIn = true;
        auth.accessToken = response.data.token;
        // auth.refreshToken = response.data.refreshToken;
        auth.friendlyUsername = response.data.friendlyUsername;

        auth.role = response.data.role;
        auth.customerRole = response.data.customerRole;

        if (response.data.role) {
            let authority = 'STANDARD_ROLE';
            if (response.data.role === 1) {
                authority = 'ADMIN_ROLE';
            }
            if (response.data.role === 2) {
                authority = 'ROLE_TENANTADMIN';
            }
            if (response.data.role === 3) {
                authority = 'STANDARD_ROLE';
            }
            if (response.data.role === 4) {
                authority = 'ACCEPTOR_ROLE';
            }
            if (response.data.role === 5) {
                authority = 'PROJECT_ROLE';
            }
            if (response.data.role === 6) {
                authority = 'PROJECT_ONLY_ROLE';
            }
            if (response.data.role === 7) {
                authority = 'ROLE_LAGER';
            }
            if (response.data.role === 8) {
                authority = 'ROLE_FILIALE';
            }
            if (response.data.role === 9) {
                authority = 'ROLE_SERVICE_TECHNIKER';
            }
            if (response.data.role === 10) {
                authority = 'ACCEPTOR_LITE_ROLE';
            }
            if (response.data.role === 11) {
                authority = 'ROLE_FERTIGUNG';
            }
            if (response.data.role === 12) {
                authority = 'ROLE_LEITER_FERTIGUNG';
            }
            auth.authorities = [authority];
        }
        if (response.data.customerRole) {
            let customerAuthority = 'ROLE_DEFAULT';
            if (response.data.customerRole === 2) {
                customerAuthority = 'ROLE_REGIONAL_MANAGER';
            }
            if (response.data.customerRole === 3) {
                customerAuthority = 'ROLE_CONTROL';
            }
            auth.customerAuthorities = [customerAuthority];
        }
        auth.userId = response.data.userId;
        auth.department = response.data.department;
        auth.auswertung = response.data.auswertung;
        auth.forecast = response.data.forecast;
        store.dispatch('auth/update', auth);
        store.dispatch('account/get', auth.userId);
    },
    store2FaToken(response) {
        const auth = store.state.auth;

        JwtService.saveToken(response.data.token);
        JwtService.saveRefreshToken(response.data.refreshToken);
        ApiService.setJwtHeader();
        auth.isLoggedIn = false;
        auth.userId = response.data.userId;
        store.dispatch('auth/update', auth);
    },
};
