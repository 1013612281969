<template>
    <div class="shadow p-3 mb-5 bg-body rounded protocol" :class="{ hide: !showProtocol }">
        <table class="table table-dark" style="background-color: transparent">
            <thead>
                <th scope="col">alter Wert</th>
                <th scope="col">neuer Wert</th>
                <th scope="col">Datum</th>
                <th scope="col">Bearbeiter</th>
            </thead>
            <tbody>
                <tr v-for="entry in protocol" :key="entry._id">
                    <td>{{ entry.oldValue }}</td>
                    <td>{{ entry.newValue }}</td>
                    <td>{{ entry.date | formatProtocolDate }}</td>
                    <td>
                        <span v-if="entry.userKuerzel">{{ entry.userKuerzel }}</span>
                        <span v-if="entry.userKuerzel == null && entry.user">{{ entry.user.abbreviation }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'Protocol',
    props: {
        protocol: {
            type: Array,
            default: () => [],
        },
        showProtocol: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="css" scoped></style>
