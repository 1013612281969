import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'orders';

const OrderprocessingService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async getUpdatedAt(slug) {
        return ApiService.get(resource + '/get/updatedAt', slug);
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async getOrdersBySearch(params) {
        return ApiService.query(resource + '/getOrdersBySearch/value', params);
    },
    async getOrdersBySerialnummerSearch(params) {
        return ApiService.query(resource + '/getOrdersBySerialnummerSearch/value', params);
    },
    async getOrdersByVkid(params) {
        return ApiService.query(resource + '/getOrdersByVkid/value', params);
    },
    async countOrdersByVkid(params) {
        let path = `countOrdersByVkid/` + params.searchVkidValue;
        return ApiService.get(resource, path);
    },
    async getAllVkidOrders(params) {
        return ApiService.query(resource + '/getVkidOrders/all', params);
    },
    async countAllVkidOrders() {
        let path = `countVkidOrders/all`;
        return ApiService.get(resource, path);
    },
    async getOrdersByEl(params) {
        return ApiService.query(resource + '/getOrdersByEl/value', params);
    },
    async countOrdersByEl(params) {
        let path = `countOrdersByEl/` + params.searchElValue;
        return ApiService.get(resource, path);
    },
    async getAllElOrders(params) {
        return ApiService.query(resource + '/getElOrders/all', params);
    },
    async countAllElOrders() {
        let path = `countElOrders/all`;
        return ApiService.get(resource, path);
    },
    async getAllAdOrders(params) {
        return ApiService.query(resource + '/getAdOrders/all', params);
    },
    async countAllAdOrders() {
        let path = `countAdOrders/all`;
        return ApiService.get(resource, path);
    },
    async countOrdersByAd(params) {
        let path = `countOrdersByAd/` + params.searchAdValue;
        return ApiService.get(resource, path);
    },
    async getOrdersByAd(params) {
        return ApiService.query(resource + '/getOrdersByAd/value', params);
    },
    async getProtocol(params) {
        let path = `getProtocolByOrder/` + params;
        return ApiService.get('protocol', path);
    },
    async sendMailTransferDepartment(params) {
        let path = `/sendMailTransferDepartment`;
        return ApiService.post(resource + path, params.params);
    },
};

export default OrderprocessingService;
