import Vue from 'vue';
import AccountService from './account.service';
import store from '@/store';
import router from '@/common/router';
import TotpService from './totp.service';

const getDefaultsChild = () => ({
    firstName: '',
    lastName: '',
    dob: null,
    positionsNummer: 0,
    properties: {},
});

const getDefaultAccount = () => ({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    role: '',
    customerRole: '',
    userInspection: [],
    is2FaUsed: false,
});

const getDefaultAccountCreate = () => ({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    role: 0,
    customerRole: 0,
    password: '',
});

const getResetAccount = () => ({
    email: null,
    tel: null,
});
const getDefaultMeta = () => ({
    success: false,
    error: false,
    errorEmailExists: false,
    notEqual: false,
    notFound: false,
    keyMissing: false,
});
const getDefaultPwd = () => ({
    password: '',
    confirmPassword: '',
});

const getDefaultTotp = () => ({
    barcodebase64img: null,
});

const getDefaultState = () => ({
    account: getDefaultAccount(),
    accountCreate: getDefaultAccountCreate(),
    resetAccount: getResetAccount(),
    meta: getDefaultMeta(),
    pwd: getDefaultPwd(),
    totp: getDefaultTotp(),
});

const account = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        clearAccount(state) {
            Vue.set(state, 'accountCreate', {});
        },
        update(state, data) {
            Vue.set(state, 'account', data);
            if (data.role === 1) {
                store.dispatch('auth/updateAuthorities', ['ADMIN_ROLE']);
            } else if (data.role === 2) {
                store.dispatch('auth/updateAuthorities', ['ROLE_TENANTADMIN']);
            } else if (data.role === 3) {
                store.dispatch('auth/updateAuthorities', ['STANDARD_ROLE']);
            } else if (data.role === 4) {
                store.dispatch('auth/updateAuthorities', ['ACCEPTOR_ROLE']);
            } else if (data.role === 5) {
                store.dispatch('auth/updateAuthorities', ['PROJECT_ROLE']);
            } else if (data.role === 6) {
                store.dispatch('auth/updateAuthorities', ['PROJECT_ONLY_ROLE']);
            } else if (data.role === 7) {
                store.dispatch('auth/updateAuthorities', ['ROLE_LAGER']);
            } else if (data.role === 8) {
                store.dispatch('auth/updateAuthorities', ['ROLE_FILIALE']);
            } else if (data.role === 9) {
                store.dispatch('auth/updateAuthorities', ['ROLE_SERVICE_TECHNIKER']);
            } else if (data.role === 10) {
                store.dispatch('auth/updateAuthorities', ['ACCEPTOR_LITE_ROLE']);
            } else if (data.role === 11) {
                store.dispatch('auth/updateAuthorities', ['ROLE_FERTIGUNG']);
            } else if (data.role === 12) {
                store.dispatch('auth/updateAuthorities', ['ROLE_LEITER_FERTIGUNG']);
            }
            if (data.customerRole === 1) {
                store.dispatch('auth/updateCustomerAuthorities', ['ROLE_DEFAULT']);
            } else if (data.customerRole === 2) {
                store.dispatch('auth/updateCustomerAuthorities', ['ROLE_REGIONAL_MANAGER']);
            } else if (data.customerRole === 3) {
                store.dispatch('auth/updateCustomerAuthorities', ['ROLE_CONTROL']);
            }
        },
        updateMeta(state, data) {
            Vue.set(state, 'meta', data);
        },
        clearMeta(state) {
            Vue.set(state, 'meta', getDefaultState().meta);
        },
        clearPwd(state) {
            Vue.set(state, 'pwd', getDefaultState().pwd);
        },
        clearResetAccount(state) {
            Vue.set(state, 'resetAccount', getDefaultState().resetAccount);
        },
        updateTotp(state, data) {
            Vue.set(state, 'totp', data);
        },
        clearTotp(state) {
            Vue.set(state, 'totp', getDefaultState().totp);
        },
        addChild(state, data) {
            if (data == null) data = Object.assign({}, getDefaultsChild());
            data.lastName = state.account.lastName;
            data.dob = Vue.moment().add(-10, 'years');

            let maxPos = Math.max.apply(
                Math,
                state.account.userInspection.map((o) => o.positionsNummer || 0)
            );
            if (maxPos < 0) maxPos = 0;
            data.positionsNummer = maxPos + 1;

            state.account.userInspection.push(data);
        },
        delChild(state, data) {
            let deleteCurrent = false;

            for (let index = 0; index < state.account.userInspection.length; ++index) {
                let pos = state.account.userInspection[index];
                if (data == null && pos == null) {
                    deleteCurrent = true;
                } else if (data._id != null) {
                    if (data._id === pos._id) deleteCurrent = true;
                } else {
                    if (data.positionsNummer === pos.positionsNummer) deleteCurrent = true;
                }

                if (deleteCurrent) {
                    state.account.userInspection.splice(index, 1);
                    break;
                }
            }
        },
    },

    actions: {
        async signup(context, client_data) {
            context.commit('clearMeta');
            delete client_data.confirmPassword;
            const { data } = await AccountService.signup(client_data);
            if (data.success) {
                context.commit('clearAccount');
                //router.push({name: 'login'});
            }

            return data;
        },
        async resendSignUpMail(context, client_data) {
            context.commit('clearMeta');
            const { data } = await AccountService.resendSignUpMail(client_data);
            if (data.success) {
                context.commit('clearAccount');
                //router.push({name: 'login'});
            }
            return data;
        },
        async update(context, client_data) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            const { _id } = client_data;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.role;
            delete client_data.customerRole;
            delete client_data.email;
            delete client_data.isVerified;
            delete client_data.isResigned;
            delete client_data.confirmationToken;
            delete client_data.resetPasswordExpires;
            delete client_data.resetPasswordToken;
            delete client_data.createdAt;
            delete client_data.updatedAt;
            const { data } = await AccountService.update(_id, client_data);
            context.commit('update', data);
            store.dispatch('auth/updateFriendlyUsername', `${data.firstName} ${data.lastName}`);

            return data;
        },
        async updatePassword(context, client_data) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            const { _id } = client_data;
            delete client_data._id;
            return await AccountService.changePassword(_id, client_data);
        },
        async resetPassword(context, email) {
            context.commit('clearMeta');
            context.commit('clearResetAccount');
            const passwordResetResponse = await AccountService.resetPassword({ email: email });
            if (passwordResetResponse && passwordResetResponse.status === 200) {
                context.commit('updateMeta', { success: true });
            }
        },
        async resetPasswordFinish(context, client_data) {
            context.commit('clearMeta');

            const { key } = client_data;
            delete client_data.key;
            const { data } = await AccountService.resetPasswordFinish(key, client_data);
            if (data.success) {
                router.push({ name: 'login' });
            }
        },
        async confirmation(context, token) {
            context.commit('clearMeta');

            const { data } = await AccountService.confirmation(token);
            if (data.success) {
                router.push({ name: 'login' });
            }
        },
        async get(context, id) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            if (id == null) id = context.rootState.auth.userId;
            const { data } = await AccountService.get(id);
            context.commit('update', data);
            return data;
        },
        addChild(context, child) {
            if (child == null) child = Object.assign({}, getDefaultsChild());
            context.commit('addChild', child);
        },
        delChild(context, child) {
            context.commit('delChild', child);
        },
        async get2FaBarcode(context, id) {
            if (id == null) id = context.rootState.auth.userId;
            const { data } = await TotpService.get2FaBarcode(id);
            const object = {
                barcodebase64img: data.qrCode,
                validationSuccess: false,
            };
            context.commit('updateTotp', object);
            return data;
        },
        async validateCode(context, client_data) {
            const response = await TotpService.validateCode(client_data);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async clearTotp(context) {
            context.commit('clearTotp');
        },
        async disable2Fa(context, client_data) {
            const response = await TotpService.disable2Fa(client_data);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async send2FaDeactivationEmail(context, client_data) {
            const response = await TotpService.send2FaDeactivationEmail(client_data);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async deactivate2Fa(context, key) {
            context.commit('clearMeta');

            const response = await TotpService.deactivate2Fa(key);
            if (response && response.status === 200) {
                context.commit('updateMeta', { success: true });
                return true;
            } else {
                context.commit('updateMeta', { success: false });
                return false;
            }
        },
    },

    getters: {
        data(state) {
            return state.account;
        },
        dataCreate(state) {
            return state.accountCreate;
        },
        dataReset(state) {
            return state.resetAccount;
        },
        meta(state) {
            return state.meta;
        },
        pwd(state) {
            return state.pwd;
        },
        totp(state) {
            return state.totp;
        },
    },
};

export default account;
