export function roleEnum() {
    return {
        ROLE_LEITER_FERTIGUNG: 12,
        ROLE_FERTIGUNG: 11,
        ACCEPTOR_LITE_ROLE: 10,
        ROLE_SERVICE_TECHNIKER: 9,
        ROLE_FILIALE: 8,
        ROLE_LAGER: 7,
        PROJECT_ONLY_ROLE: 6,
        PROJECT_ROLE: 5,
        ACCEPTOR_ROLE: 4,
        STANDARD_ROLE: 3,
        LIST_ADMIN_ROLE: 2,
        ADMIN_ROLE: 1,
    };
}

export function customerRoleEnum() {
    return {
        ROLE_DEFAULT: 1,
        ROLE_REGIONAL_MANAGER: 2,
        ROLE_CONTROL: 3,
    };
}
