import Vue from 'vue';
import CustomerlocationService from './customerlocation.service';
import * as _ from 'lodash';

const getDefaultAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultItem = () => ({
    _id: null,
    znl: null,
    nr: null,
    sapNr: null,
    address: getDefaultAddress(),
    bemerkungen: null,
    books: [],
    inspectionAmpel: null,
    selfInspectionAmpel: null,
    active: true,
    ruhend: false,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
});

const customerlocation = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await CustomerlocationService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await CustomerlocationService.update(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await CustomerlocationService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await CustomerlocationService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data);
            return data;
        },
        async getAmpelCount(context, params) {
            return await CustomerlocationService.getAmpelCount(params);
        },
        async delete(context, slug) {
            const { data } = await CustomerlocationService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};
export { getDefaultItem };
export default customerlocation;
