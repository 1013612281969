import Vue from 'vue';
import Toasted from 'vue-toasted';
import VueI18n from '@/i18n';

const Toast = {
    options: {
        theme: 'outline',
        position: 'bottom-right',
        duration: 3000,
        iconPack: 'fontawesome',
    },

    init() {
        Vue.use(Toasted, this.options);
    },

    showSaveOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showLinkOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.link_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showSaveOkToastText(text) {
        Vue.toasted.show(text, { icon: 'check' });
    },

    showDeleteOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.delete_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showCreateOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.create_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showSaveCapacityOkToast() {
        Vue.toasted.show(VueI18n.tc('Erfolgreich in Kapazitäten eingeplant'), { icon: 'check' });
    },

    askDelete(i18nConst) {
        if (!i18nConst) i18nConst = 'entity.delete.msg';
        return confirm(VueI18n.tc(i18nConst));
    },

    ask(i18nConst, callback, cancelCallback, i18nConstDo) {
        let doText = 'Generieren';
        if (i18nConstDo) {
            doText = VueI18n.tc(i18nConstDo);
        }
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            theme: 'toasted-primary',
            position: 'top-center',
            duration: null,
            action: [
                {
                    text: VueI18n.tc('entity.action.cancel'),
                    onClick: (e, toastObject) => {
                        cancelCallback();
                        toastObject.goAway(0);
                    },
                },
                {
                    text: doText,
                    onClick: (e, toastObject) => {
                        callback();
                        toastObject.goAway(0);
                    },
                },
            ],
        });
    },

    showSaveErrorToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_nok';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '8000',
        });
    },

    showAlreadyLinkedToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.already_linked';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '8000',
        });
    },

    showSaveErrorToastText(text) {
        Vue.toasted.show(text, {
            icon: 'warning',
            theme: 'bubble',
            duration: '8000',
        });
    },
};

export default Toast;
