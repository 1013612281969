import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import { localStoragePlugin } from './plugins';
import auth from '@/app/auth/auth.store';
import account from '@/app/account/account.store';
import user from '@/app/user/user.store';
import tenant from '@/app/tenant/tenant.store';
import book from '@/app/book/book.store';
import booktype from '@/app/booktype/booktype.store';
import useradministration from '@/app/administration/useradministration.store';
import admin from '@/app/administration/admin.store';
import inspection from '@/app/inspection/inspection.store';
import country from '@/app/country/country.store';
import folder from '@/app/folder/folder.store';
import auditlogs from '@/app/auditlogs/auditlogs.store';
import pdfassets from '@/app/pdfassets/pdfassets.store';
import orderprocessing from '@/app/orderprocessing/orderprocessing.store';
import opevaluation from '@/app/opevaluation/opevaluation.store';
import customerlocation from '@/app/customerlocation/customerlocation.store';
import costlist from '@/app/costlist/costlist.store';
import capacities from '@/app/capacities/capacities.store';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        packageVersion: JSON.stringify(require('../../package.json').version),
    },
    getters: {
        appVersion: (state) => state.packageVersion,
    },
    modules: {
        common,
        auth,
        account,
        user,
        tenant,
        book,
        useradministration,
        admin,
        booktype,
        inspection,
        country,
        folder,
        auditlogs,
        orderprocessing,
        pdfassets,
        opevaluation,
        customerlocation,
        costlist,
        capacities,
    },
    plugins: [localStoragePlugin],
});
